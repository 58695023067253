<template>
  <v-navigation-drawer
    app
    :value="isSidebarDisplayed"
    @input="$emit('update:isSidebarDisplayed', $event)"
  >
    <!-- Logo Item-->
    <v-list-item center class="logo-wrapper">
      <v-list-item-content>
        <v-img :max-height="24" contain :src="require('@/assets/logo.png')" />
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <!-- Entity Item -->
    <v-list-item two-line class="px-4 entity-wrapper">
      <v-list-item-avatar :color="entityLogo ? 'transparent' : 'primary'">
        <img v-if="entityLogo" :src="entityLogo">
        <span v-else class="white--text">{{ entityName.slice(0, 2) }}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ entityName }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="parentEntityName"
          class="d-flex flex-row align-center"
        >
          <v-icon
            color="primary"
            size="16"
          >
            arrow_back
          </v-icon>
          <router-link v-if="parentEntityRoute" :to="parentEntityRoute">
            {{ `${$t('common.goTo')} ${parentEntityName}` }}
          </router-link>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <!-- TODO: CRIS: separar en un componente de tipo select con listado de subitems -->
        <!-- Menu -->
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn v-if="entityList.length" icon color="white lighten-1" dark v-on="on">
              <v-icon color="black">
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="entity in entityList" :key="entity.id" :to="entity.route">
              <v-list-item-avatar :color="entity.logo ? 'transparent' : 'primary'">
                <img v-if="entity.logo" :src="entity.logo">
                <span v-else class="white--text">{{ entity.name.slice(0, 2) }}</span>
              </v-list-item-avatar>
              <v-list-item-title>{{ entity.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>

    <v-divider />
    <!-- Nav Items -->
    <v-list dense nav>
      <template v-for="(item) in navItems">
        <sidebar-title
          v-if="item.type === 'title'"
          :key="item.id"
          :title="item.title"
          :tooltip="item.tooltip"
        />
        <sidebar-custom-button
          v-else-if="item.type === 'button'"
          :key="item.id"
          :item="item"
        />
        <v-divider
          v-else-if="item.type ==='divider'"
          :key="item.id"
          class="mb-2"
        />
        <sidebar-routing-item
          v-else-if="item.subItems.length === 0"
          :key="item.name"
          :item="item"
          :route="currentViewRoute"
        />
        <sidebar-sub-items
          v-else
          :key="item.name"
          :item="item"
          :route="$router.history.current.params"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'Sidebar',
  components: {
    SidebarTitle: () => import('@/components/sidebarItems/SidebarTitle'),
    SidebarRoutingItem: () => import('@/components/sidebarItems/SidebarRoutingItem'),
    SidebarSubItems: () => import('@/components/sidebarItems/SidebarSubItems'),
    SidebarCustomButton: () => import('@/components/sidebarItems/SidebarCustomButton'),
  },
  props: {
    entityName: { type: String, required: true },
    entityLogo: { type: String, default: '' },
    entityList: { type: Array, required: true },
    parentEntityName: { type: String, default: null },
    parentEntityRoute: { type: Object, default: null },
    items: { type: Array, required: true },
    isSidebarDisplayed: { type: Boolean },
    currentViewRoute: { type: String, default: null },
  },
  computed: {
    navItems() {
      const getItemAndSubItems = item => ({
        ...item,
        subItems: this.items.filter(item2 => item2.parentId === item.id).map(subItem => getItemAndSubItems(subItem)),
      })
      const parentItems = this.items.filter(item => !item.parentId).map(item => getItemAndSubItems(item))
        .map(item => item.subItems.length === 1 ? { ...item.subItems[0], icon: item.icon } : item)
      const filteredParentItems = parentItems.filter(item => item.route || item.subItems.length || item.isStyle || item.action)
      return filteredParentItems
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-wrapper {
  height: 63px;
}

.entity-wrapper {
  height: 87px;
}
</style>
